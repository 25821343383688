import React, {Fragment} from 'react';
import {Button, Grid, withStyles} from '@material-ui/core';
import styles from '../styles';
import PropTypes from "prop-types";
import classNames from 'classnames';
import icons from './../../../assets/icons';

function IconComponent(props) {
    const {classes, editComponent, component} = props;
    const renderImage = () => {
        const data = icons[component.Icon];
        if (data)
            return (<img src={data.icon} alt={component.Name} className={classes.image} style={{maxWidth: '75%'}}/>);
        return <Fragment/>
    };

    const buttons = [];
    for (let key in icons) {
        buttons.push(
            <Button
                key={key}
                variant={component.Icon === key ? 'contained' : 'outlined'}
                className={classes.componentTypePickerButton}
                onClick={() => {
                    editComponent('Icon', key);
                    editComponent('Name', icons[key].dutchReadable);
                }}
            >
                {icons[key].dutchReadable}
            </Button>
        )
    }

    return (
        <Fragment>
            <Grid container className={classNames([classes.root, classes.rootHeightEncase])} spacing={16}>
                <Grid item md={4} xs={12} className={classes.rootHeightEncaseComponents}>
                    {buttons}
                </Grid>
                <Grid item md={8} xs={12} className={classes.rootHeightEncaseComponents}>
                    {renderImage()}
                </Grid>
            </Grid>
        </Fragment>
    )
}

IconComponent.propTypes = {
    classes: PropTypes.object,
    editComponent: PropTypes.func,
    component: PropTypes.object,
    name: PropTypes.string,
};

export default withStyles(styles)(IconComponent);