import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

export default theme => ({
    root: {
        flexGrow: 1,
    },
    themeMargin: {
        margin: theme.spacing.unit,
    },
    verticalCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    overflowYAuto: {
        overflowY: 'auto'
    },
    newsList: {
        maxHeight: `500px`
    },
    newsItem: {
        marginBottom: theme.spacing.unit * 4,
    },
    componentTypePickerLayout: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
    },
    componentTypePickerButton: {
        width: '150px',
        margin: theme.spacing.unit,
    },
    paper: {
        position: 'absolute',
        width: '75%',
        height: '75%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
    editorStyle: {
        minHeight: '350px',
        border: '1px solid #F1F1F1',
        padding: '5px',
        borderRadius: '2px'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: '200px',
    },
    fab: {
        margin: theme.spacing.unit,
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    image: {
        maxWidth: '100%',
    },
    rootHeightEncase: {
        maxHeight: '75%',
    },
    rootHeightEncaseComponents: {
        maxheight: '100%',
    },
    singleComponentCard: {
        width: '100%',
    }
});