import React, {Component} from "react";
import {connect} from "react-redux";
import classNames from 'classnames';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToApp from '@material-ui/icons/ExitToApp';
import {withStyles} from "@material-ui/core";
import {LOGOUT} from "../../constants/ActionTypes";
import PropTypes from "prop-types";

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
    h5: {
        marginBottom: theme.spacing.unit * 2,
    },
});

class TopBar extends Component {

    static propTypes = {
        classes: PropTypes.object,
        account: PropTypes.object,
        handleDrawerClose: PropTypes.func,
        handleDrawerOpen: PropTypes.func,
        handleLogout: PropTypes.func,
        drawerOpen: PropTypes.bool,
    };

    renderLogoutButton = () => {
        const {account} = this.props;
        if (account.Username) {
            return (
                <IconButton color="inherit" onClick={this.props.handleLogout}>
                    <Badge>
                        <ExitToApp/>
                    </Badge>
                </IconButton>
            )
        }
        return null;
    };

    render() {
        const {classes, drawerOpen} = this.props;

        return (
            <AppBar
                position="absolute"
                className={classNames(classes.appBar, drawerOpen && classes.appBarShift)}
            >
                <Toolbar disableGutters={!drawerOpen} className={classes.toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.props.handleDrawerOpen}
                        className={classNames(
                            classes.menuButton,
                            drawerOpen && classes.menuButtonHidden,
                        )}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        Dashboard
                    </Typography>
                    {this.renderLogoutButton()}
                </Toolbar>
            </AppBar>
        )
    }
}

const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch({type: LOGOUT}),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TopBar));

