import React, { Fragment} from 'react';
import {Grid, TextField, withStyles} from '@material-ui/core';
import styles from '../styles';
import PropTypes from "prop-types";
import classNames from 'classnames';

function ImageComponent(props) {
    const {classes, editComponent, component} = props;
    const renderImage = () => {
        if (component.Url)
            return (<img src={component.Url} alt={component.Name} className={classes.image}/>);
        return <Fragment/>
    };

    return (
        <Fragment>
            <Grid container className={classNames([classes.root, classes.rootHeightEncase])} spacing={16}>
                <Grid item md={4} xs={12} className={classes.rootHeightEncaseComponents}>
                    <TextField
                        value={component.Url || ''}
                        label="Link"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        onChange={e => editComponent('Url', e.target.value)}
                    />
                </Grid>
                <Grid item md={8} xs={12} className={classes.rootHeightEncaseComponents}>
                    {renderImage()}
                </Grid>
            </Grid>
        </Fragment>
    )
}

ImageComponent.propTypes = {
    classes: PropTypes.object,
    editComponent: PropTypes.func,
    component: PropTypes.object,
    url: PropTypes.string,
};

export default withStyles(styles)(ImageComponent);