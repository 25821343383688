import React, {Component, Fragment} from 'react';
import {withStyles, TextField, Typography, Grid, Fab, Card, CardContent} from '@material-ui/core';
import {DatePicker} from "material-ui-pickers";
import styles from '../styles';
import editorToolbarOptions from "./editorToolbarOptions";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import classNames from 'classnames';
import {createNewsItem} from "../../../lib/Fetch";
import moment from "moment";
import PropTypes from "prop-types";

class NewsComponent extends Component {

    static propTypes = {
        classes: PropTypes.object,
        component: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            news: props.component.News || [],
            currentNews: this.createEmptyNewsItem(),
            text: null
        };
    }

    createEmptyNewsItem = () => {
        return {
            Title: '',
            PublicationStart: moment(),
            PublicationEnd: moment().add(7, 'days'),
        }
    };

    handleSaveNewsItem = () => {
        const newsItem = this.state.currentNews;
        const component = this.props.component;

        createNewsItem(newsItem, component)
            .then(body => {
                if(body.news) {
                    const {news} = this.state;
                    news.push(body.news);
                    return this.setState({news, currentNews: this.createEmptyNewsItem(), text: null});
                }
            })
    };

    handleChange = (field, value) => {
        const {currentNews} = this.state;
        currentNews[field] = value;
        if(field === 'PublicationStart' && currentNews.PublicationStart > currentNews.PublicationEnd) {
            currentNews.PublicationEnd = currentNews.PublicationStart.clone().add(7, 'days');
        }

        this.setState({currentNews});
    };

    onEditorStateChange = (text) => {
        this.handleChange('Message', draftToHtml(convertToRaw(text.getCurrentContent())));
        this.setState({text});
    };

    renderNewsList = () => {
        const {classes} = this.props;
        const {news} = this.state;
        if (news.length === 0) {
            return (
                <Typography variant="subtitle1" component="p">
                    Geen berichten gevonden
                </Typography>
            )
        } else {
            return news.sort((a,b) => a.PublicationStart - b.PublicationStart).map((item, index) => (
                <Card key={index} className={classes.newsItem}>
                    <CardContent>
                        <Typography variant="subtitle2" component="p">
                            {moment(item.PublicationStart).format('DD/MM/YYYY')} - {moment(item.PublicationEnd).format('DD/MM/YYYY')}
                        </Typography>
                        <Typography variant="h5" component="p">
                            {item.Title}
                        </Typography>
                        <div dangerouslySetInnerHTML={{__html: item.Message}}/>
                    </CardContent>
                </Card>
            ))
        }
    };

    render() {
        const {classes} = this.props;
        const {currentNews, text} = this.state;

        return (
            <Fragment>
                <Grid container className={classes.root} spacing={16}>
                    <Grid item spacing={8} md={8} xs={12}>
                        <div>
                            <DatePicker
                                value={currentNews.PublicationStart}
                                disablePast
                                onChange={(date) => this.handleChange('PublicationStart', date)}
                                label="Publicatie start"
                                className={classes.textField}
                                showTodayButton
                            />
                            <DatePicker
                                value={currentNews.PublicationEnd}
                                disablePast
                                onChange={(date) => this.handleChange('PublicationEnd', date)}
                                className={classes.textField}
                                label="Publicatie stop"
                            />
                        </div>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <TextField
                                value={currentNews.Title || ''}
                                label="Bericht titel"
                                className={[classes.textField]}
                                margin="normal"
                                variant="outlined"
                                onChange={e => this.handleChange('Title', e.target.value)}
                            />
                            <Fab variant="extended" color="primary" className={classNames(classes.themeMargin)}
                                 onClick={this.handleSaveNewsItem}>
                                Opslaan
                            </Fab>
                        </Grid>
                        <Editor
                            editorState={text}
                            wrapperClassName="demo-wrapper"
                            editorClassName={classes.editorStyle}
                            onEditorStateChange={this.onEditorStateChange}
                            toolbar={editorToolbarOptions}
                        />
                    </Grid>
                    <Grid item spacing={8} md={4}>
                        <Typography variant="title" component="p">
                            Berichten lijst
                        </Typography>
                        <div className={classNames(classes.overflowYAuto, classes.newsList)}>
                            {this.renderNewsList()}
                        </div>
                    </Grid>
                </Grid>

            </Fragment>
        )
    }
}

export default withStyles(styles)(NewsComponent);