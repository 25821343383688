import React, {Component} from 'react';
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import {ADD_SLIDE, SET_SLIDES} from "../../constants/ActionTypes";
import {IconButton, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {SortableContainer as sortableContainer, SortableElement as sortableElement} from 'react-sortable-hoc';
import {createSlide, getSlides, patchSlides, removeSlide} from "../../lib/Fetch";
import arrayMove from 'array-move';
import DeleteForever from '@material-ui/icons/DeleteForever';
import PropTypes from 'prop-types';

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    icon: {
        marginRight: theme.spacing.unit * 2,
    },
    heroUnit: {
        backgroundColor: theme.palette.background.paper,
    },
    heroContent: {
        maxWidth: 600,
        margin: '0 auto',
        padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
    },
    heroButtons: {
        marginTop: theme.spacing.unit * 4,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    cardGrid: {
        padding: `${theme.spacing.unit * 8}px 0`,
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    deleteForeverIcon: {
        position: 'relative',
        top: 0,
        right: 0,
        float: 'right',
        marginTop: '-10px',
        marginRight: '-10px',
    },
    paper: {
        position: 'absolute',
        width: '50%',
        height: '50%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
});

const SortableItem = sortableElement(({slide, classes, removeSlide, editSlide}) => (
    <Grid item key={slide.Order} sm={12} md={6} lg={4}>
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                    Pagina {slide.Order}
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={() => removeSlide(slide)}
                        className={classNames(
                            classes.menuButton,
                            classes.deleteForeverIcon
                        )}
                    >
                        <DeleteForever/>
                    </IconButton>
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary" onClick={() => editSlide(slide)}>
                    Bewerken
                </Button>
            </CardActions>
        </Card>
    </Grid>
));

const SortableContainer = sortableContainer(({children}) => {
    return (
        <Grid container spacing={40}>
            {children}
        </Grid>
    );
});

class SlidesScreen extends Component {

    static propTypes = {
        account: PropTypes.object,
        setSlides: PropTypes.func,
        history: PropTypes.object,
        classes: PropTypes.object,
        addSlide: PropTypes.func,
    };

    state = {
        slides: [],
        updated: false,
    };

    componentDidMount = () => {
        this.getSlides();
    };

    getSlides = () => {
        getSlides(this.props.account.EnergyScreen.Code)
            .then(body => {
                const slides = this.sortSlides(body.slides);
                this.props.setSlides(slides);
                this.setState({slides});
            });
    };

    createSlide = () => {
        const order = Math.max(...this.state.slides.map(slide => slide.Order)) + 1;
        createSlide(this.props.account.EnergyScreen.Code, order)
            .then(body => {
                if (body.error) {
                    //TODO show some sort of error
                    return;
                }
                this.props.addSlide(body.slides);
                this.setState({slides: [...this.state.slides, body.slides]});
            })
    };

    removeSlide = slide => {
        removeSlide(slide)
            .then(body => {
                if (body.slides.success) {
                    this.getSlides();
                }
            });
    };

    sortSlides = (slides) => {
        return slides.sort((a, b) => a.Order - b.Order);
    };

    editSlide = (slide) => {
        this.props.history.push(`/blocks/${slide.Id}`)
    };

    updateSlides = () => {
        patchSlides(this.state.slides)
            .then(() => { //body
                //TODO Show update success
            })
    };

    onSortEnd = ({oldIndex, newIndex}) => {
        const slides = [...arrayMove(this.state.slides, oldIndex, newIndex)];
        this.setState({slides});

        setTimeout(() => {
            slides.forEach((slide, index) => {
                slide.Order = index + 1;
            });

            this.setState({slides, updated: true});
            this.updateSlides();
        }, 500)
    };

    render() {
        const {classes} = this.props;
        return (
            <main>
                <div className={classes.heroUnit}>
                    <div className={classes.heroContent}>
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            Energiescherm
                        </Typography>
                        <Typography variant="h6" align="center" color="textSecondary" paragraph>
                            Hier kunt U nieuwe pagina toevoegen of verwijderen.
                        </Typography>
                        <div className={classes.heroButtons}>
                            <Grid container spacing={16} justify="center">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={this.createSlide}>
                                        Nieuwe pagina toevoegen
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className={classNames(classes.layout, classes.cardGrid)}>
                    <SortableContainer
                        axis="xy"
                        onSortEnd={this.onSortEnd}
                        pressDelay={300}
                    >
                        {this.state.slides.map((slide, index) => (
                            <SortableItem key={`item-${slide.Order}`} removeSlide={this.removeSlide}
                                          editSlide={this.editSlide}
                                          index={index}
                                          slide={slide}
                                          classes={classes}
                            />
                        ))}
                    </SortableContainer>
                </div>
            </main>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account,
    slide: state.slide,
});

const mapDispatchToProps = dispatch => ({
    setSlides: slides => dispatch({type: SET_SLIDES, payload: slides}),
    addSlide: slide => dispatch({type: ADD_SLIDE, payload: slide}),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SlidesScreen));