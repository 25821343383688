import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core';
import styles from '../styles';
import PropTypes from "prop-types";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {Editor} from "react-draft-wysiwyg";
import editorToolbarOptions from "./editorToolbarOptions";

class LegacyKioskComponent extends Component {

    static propTypes = {
        classes: PropTypes.object,
        editComponent: PropTypes.func,
        component: PropTypes.object,
    };

    state = {
        text: ''
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {component} = this.props;
        const blocksFromHtml = htmlToDraft(component.News || '');
        const {contentBlocks, entityMap} = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({text: editorState});
    }

    onEditorStateChange = (text) => {
        this.setState({text});
        this.props.editComponent('News', draftToHtml(convertToRaw(text.getCurrentContent())));
    };

    render() {
        const {classes} = this.props;

        return (
            <Fragment>
                <div style={{width: '100%'}}>
                    <p>De oude kiosk wordt weergegeven. Het uiterlijk van de kiosk is op basis van het gekozen
                        thema.</p>
                    <p>De oude kiosk zal over de volledige grootte van het scherm worden weergegeven. Het toevoegen van
                        andere blokken gaat dus niet meer.</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <div style={{display: 'flex', height: '100%', flexGrow: 1, flexDirection:'column'}}>
                        <p>News</p>
                        <Editor
                            editorState={this.state.text}
                            wrapperClassName="demo-wrapper"
                            editorClassName={classes.editorStyle}
                            onEditorStateChange={this.onEditorStateChange}
                            toolbar={editorToolbarOptions}
                        />
                    </div>
                    <div style={{display: 'flex', height: '100%', flexGrow: 1}}>

                    </div>
                    <div style={{display: 'flex', height: '100%', flexGrow: 1}}>

                    </div>

                </div>
            </Fragment>
        )
    }
}

export default withStyles(styles)(LegacyKioskComponent);