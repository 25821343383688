import React, {Fragment} from 'react';
import {Button, Grid, withStyles} from '@material-ui/core';
import styles from '../styles';
import PropTypes from "prop-types";

function DataComponent(props) {
    const {classes, editComponent, component} = props;

    return(
        <Fragment>
            <Grid container className={classes.root} spacing={16}>
                <Grid item md={3} xs={12}>
                    <p>Type data</p>
                    <Button
                        variant={component.DataType === 'Yield' ? 'contained' : 'outlined'}
                        className={classes.componentTypePickerButton}
                        onClick={() => editComponent('DataType', 'Yield')}
                    >
                        Opwek
                    </Button>
                    <Button
                        variant={component.DataType === 'ConsumptionUsage' ? 'contained' : 'outlined'}
                        className={classes.componentTypePickerButton}
                        onClick={() => editComponent('DataType', 'ConsumptionUsage')}
                    >
                        Inkoop
                    </Button>
                    <Button
                        variant={component.DataType === 'ConsumptionReturn' ? 'contained' : 'outlined'}
                        className={classes.componentTypePickerButton}
                        onClick={() => editComponent('DataType', 'ConsumptionReturn')}
                    >
                        Teruglevering
                    </Button>
                </Grid>

                <Grid item md={3}>
                    <p>Periode </p>
                    <Button
                        variant={component.PeriodType === 'Daily' ? 'contained' : 'outlined'}
                        className={classes.componentTypePickerButton}
                        onClick={() => editComponent('PeriodType', 'Daily')}
                    >
                        Per dag
                    </Button>
                    <Button
                        variant={component.PeriodType === 'Monthly' ? 'contained' : 'outlined'}
                        className={classes.componentTypePickerButton}
                        onClick={() => editComponent('PeriodType', 'Monthly')}
                    >
                        Per maand
                    </Button>
                </Grid>
            </Grid>
        </Fragment>
    )
}

DataComponent.propTypes = {
    classes: PropTypes.object,
    editComponent: PropTypes.func,
    component: PropTypes.object,
};

export default withStyles(styles)(DataComponent);