import EmptyComponent from './_EmptyComponent';
import DataComponent from './DataComponent';
import IconComponent from './IconComponent';
import LegacyKioskComponent from './LegacyKioskComponent';
import NewsComponent from './NewsComponent';
import TextComponent from './TextComponent';
import ImageComponent from './ImageComponent';
import PanelInfoComponent from './PanelInfoComponent';
import TwitterfeedComponent from './TwitterfeedComponent';
import MetaphorComponent from './MetaphorComponent';

export default {
    EmptyComponent,
    DataComponent,
    IconComponent,
    LegacyKioskComponent,
    ImageComponent,
    PanelInfoComponent,
    NewsComponent,
    TextComponent,
    TwitterfeedComponent,
    MetaphorComponent
}