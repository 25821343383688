import {
    SET_ACCOUNT,
    LOGOUT
} from './../constants/ActionTypes';

const initialAccount = {
    Username: '',
    LocationId: null,
    Firstname: null,
    Lastname: null,
    EnergyScreen: null
};

const account = (state = {...initialAccount}, action) => {
    switch (action.type) {
        case SET_ACCOUNT:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return {
                ...state,
                ...initialAccount
            };
        default:
            return state;
    }
};

export default account;
