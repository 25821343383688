import React, {Component, Fragment} from 'react';
import {Modal, withStyles, TextField, Button, Typography, Grid, Fab, IconButton} from "@material-ui/core";
import Components from './Components';
import Dictionary from './Components/Dictionary';
import styles from './styles'
import DeleteForever from '@material-ui/icons/DeleteForever';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ComponentModal extends Component {

    static propTypes = {
        editComponent: PropTypes.func,
        component: PropTypes.object,
        slide: PropTypes.object,
        classes: PropTypes.object,
        reloadComponents: PropTypes.func,
        handleClose: PropTypes.func,
        open: PropTypes.bool,
        saveComponent: PropTypes.func,
        removeComponent: PropTypes.func,
    };

    state = {
        snackbarOpen: false,
        snackbarType: false,
        snackbarMessage: '',
    };

    static getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    showSnackbar = (message, type) => {
        this.setState({snackbarOpen: true, snackbarType: type, snackbarMessage: message});

        setTimeout(() => {
            this.setState({snackbarOpen: false, snackbarMessage: ''});
        }, 2500);
    };

    removeComponent = (success) => {
        this.props.removeComponent(() => {
            if (success)
                this.showSnackbar('Verwijderen gelukt', true);
            else
                this.showSnackbar('Er ging iets fout met verwijderen, probeer het zo nog eens', false);
        });
    };

    saveComponent = () => {
        this.props.saveComponent(success => {
            if (success)
                this.showSnackbar('Opslaan gelukt', true);
            else
                this.showSnackbar('Er ging iets fout met opslaan, probeer het zo nog eens', false);
        })
    };

    changeType = newType => {
        this.props.editComponent('Type', newType);
    };

    getCorrespondingReactComponent = () => {
        const {component} = this.props;
        return Components[component.Type];
    };

    generateChangeComponentTypeButtons = () => {
        const {classes} = this.props;
        const buttons = [];
        for (let key in Dictionary) {
            if (key !== 'EmptyComponent' && !(Dictionary[key] instanceof Function)) {
                buttons.push(
                    <Button
                        key={key}
                        variant="outlined"
                        className={classes.componentTypePickerButton}
                        onClick={() => this.changeType(key)}
                    >
                        {Dictionary[key].dutchReadable}
                    </Button>
                )
            }
        }
        return buttons;
    };

    renderComponent = () => {
        const {component, editComponent, reloadComponents} = this.props;

        const Comp = this.getCorrespondingReactComponent();
        return (
            <Comp component={component} editComponent={editComponent} reloadComponents={reloadComponents}/>
        );
    };

    renderContent = () => {
        const {classes, component} = this.props;

        if (component.Type === 'EmptyComponent') {
            return (
                <div style={ComponentModal.getModalStyle()} className={classes.paper}>
                    <TextField
                        disabled
                        value="Leeg"
                        id="block-type-outlined-disabled"
                        label="Blok"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                    />
                    <hr/>
                    <Typography variant="subtitle1" id="simple-modal-description">
                        Selecteer een type block om op deze positie te gebruiken:
                    </Typography>
                    <div className={classes.componentTypePickerLayout}>
                        {this.generateChangeComponentTypeButtons()}
                    </div>
                </div>
            )
        } else {
            const disableNameInputFor = ['IconComponent', 'PanelInfoComponent'];
            return (
                <Fragment>
                    <div style={ComponentModal.getModalStyle()} className={classes.paper}>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                            position: 'fixed',
                            top: 0,
                            right: 0
                        }}>
                            <IconButton aria-label="close" className={classes.button}
                                 onClick={this.props.handleClose}
                            >
                                <Close/>
                            </IconButton>
                        </div>

                        <Grid container
                              direction="row"
                              justify="space-between"
                              alignItems="center">
                            <div>
                                <TextField
                                    disabled
                                    value={Dictionary.getDutchReadable(component.Type)}
                                    id="block-type-outlined-disabled"
                                    label="Blok"
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <TextField
                                    value={component.Name || ''}
                                    label="Naam"
                                    disabled={disableNameInputFor.includes(component.Type)}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={e => this.props.editComponent('Name', e.target.value)}
                                />
                            </div>
                            <div>
                                <Fab color="secondary" aria-label="Delete" className={classes.fab}
                                     onClick={this.removeComponent}
                                >
                                    <DeleteForever/>
                                </Fab>
                                <Fab color="primary" aria-label="Save" className={classes.fab}
                                     onClick={this.saveComponent}
                                >
                                    <Save/>
                                </Fab>
                            </div>
                        </Grid>
                        <hr/>
                        {this.renderComponent()}
                    </div>
                </Fragment>
            );
        }
    };

    render() {
        const {open, handleClose, classes} = this.props;

        const variant = this.state.snackbarType ? 'success' : 'error';

        return (
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Fragment>
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                        open={this.state.snackbarOpen}
                        ContentProps={{
                            'aria-describedby': 'message',
                        }}
                    >
                        <SnackbarContent
                            className={classNames(classes[variant], classes.margin)}
                            message={<span id="message">{this.state.snackbarMessage}</span>}
                        />
                    </Snackbar>
                    {this.renderContent()}
                </Fragment>
            </Modal>
        )
    }
}

export default withStyles(styles)(ComponentModal);
