import React, {Component} from 'react';
import {connect} from "react-redux";
import classNames from 'classnames';
import QrReader from 'react-qr-reader'
import {SET_ACCOUNT} from "../../constants/ActionTypes";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import {activateScreen} from "../../lib/Fetch";
import PropTypes from "prop-types";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        flexGrow: 1,
        height: '100%',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '50%'
        },
    },
    h5: {
        marginBottom: theme.spacing.unit * 2,
    },
});

class ScanQRScreen extends Component {

    static propTypes = {
        classes: PropTypes.object,
        account: PropTypes.object,
        setAccount: PropTypes.func,
    };

    sendingCode = false;

    state = {
        result: '',
    };

    handleScan = data => {
        if (data && !this.sendingCode) {
            this.sendingCode = true;

            activateScreen(this.props.account.Username, data)
                .then(body => {
                    if (body.energyscreens.Code && body.energyscreens.Username)
                        this.props.setAccount({EnergyScreen: body.energyscreens});
                })
                .catch(() => {
                    this.sendingCode = false;
                })
        }
    };

    handleError = () => {
    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classNames(classes.container)}>
                <Typography
                    variant="h4"
                    gutterBottom
                    component="h2"
                    className={classes.title}
                >
                    Scan
                    Energiescherm
                    QR
                </Typography>
                <QrReader
                    delay={300}
                    onError={this.handleError}
                    facingMode={'environment'}
                    onScan={this.handleScan}
                    style={{width: '100%'}}
                />
                <p> {this.state.result}</p>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = dispatch => ({
    setAccount: account => dispatch({type: SET_ACCOUNT, payload: account}),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ScanQRScreen));
