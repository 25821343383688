import React, {Component} from 'react';
import Drawer from "@material-ui/core/Drawer";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import MainListItems from "./listItems";
import PropTypes from 'prop-types';

class Sidebar extends Component {

    static propTypes = {
        classes: PropTypes.object,
        handleDrawerClose: PropTypes.func,
        drawerOpen: PropTypes.bool,
    };

    render() {
        const {classes, handleDrawerClose, drawerOpen} = this.props;

        return (
            <Drawer
                variant="permanent"
                classes={{
                    paper: classNames(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
                }}
                open={drawerOpen}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider/>
                <List><MainListItems {...this.props}/></List>
            </Drawer>
        );
    }
}

export default Sidebar;
