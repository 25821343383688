import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LayersIcon from '@material-ui/icons/Layers';
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from "prop-types";

export default function listItems(props) {
    return (
        <div>
            <ListItem button onClick={() => props.history.push('/pages')}>
                <ListItemIcon>
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary="Pagina's"/>
            </ListItem>
            <ListItem button onClick={() => props.history.push('/widget')}>
                <ListItemIcon>
                    <LayersIcon/>
                </ListItemIcon>
                <ListItemText primary="Widgets"/>
            </ListItem>
            <ListItem button onClick={() => props.history.push('/theme')}>
                <ListItemIcon>
                    <SettingsIcon/>
                </ListItemIcon>
                <ListItemText primary="Thema"/>
            </ListItem>
        </div>
    )
}

listItems.propTypes = {
    history: PropTypes.object,
};
