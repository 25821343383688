import React, {Fragment} from 'react';
import {withStyles} from '@material-ui/core';
import styles from '../styles';

function MetaphorComponent() {
    return (
        <Fragment>
            <p>Er worden een aantal metaforen weergegeven die doorwisselen.</p>
        </Fragment>
    )
}

export default withStyles(styles)(MetaphorComponent);