import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core';
import styles from '../styles';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import editorToolbarOptions from "./editorToolbarOptions";
import PropTypes from "prop-types";

class TextComponent extends Component {

    static propTypes = {
        classes: PropTypes.object,
        editComponent: PropTypes.func,
        component: PropTypes.object,
    };

    state = {
        text: ''
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {component} = this.props;
        const blocksFromHtml = htmlToDraft(component.Text || '');
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({text: editorState});
    }

    onEditorStateChange = (text) => {
        this.setState({text});
        this.props.editComponent('Text',  draftToHtml(convertToRaw(text.getCurrentContent())));
    };

    render() {
        const {classes} = this.props;

        return (
            <Fragment>
                <Editor
                    editorState={this.state.text}
                    wrapperClassName="demo-wrapper"
                    editorClassName={classes.editorStyle}
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={editorToolbarOptions}
                />
            </Fragment>
        )
    }
}


export default withStyles(styles)(TextComponent);