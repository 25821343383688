import CONSTANTS from "../constants/CONSTANTS";

export const activateScreen = (Username, screenCode) => {
    return fetch(`${CONSTANTS.SERVER_URL}/energyscreens/activate`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Username,
            screenCode
        }),
    }).then(res => res.json())
};

export const getAccount = () => {
    return fetch(`${CONSTANTS.SERVER_URL}/accounts/me`, {
        credentials: 'include'
    }).then(res => res.json())
};

export const getScreen = (code) => {
    return fetch(`${CONSTANTS.SERVER_URL}/energyscreens/${code}`, {
        credentials: 'include'
    }).then(res => res.json())
};

export const patchScreen = (code, data) => {
    return fetch(`${CONSTANTS.SERVER_URL}/energyscreens/${code}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(res => res.json())
};

export const logout = () => {
    return fetch(`${CONSTANTS.SERVER_URL}/accounts/logout`, {
        credentials: 'include'
    })
        .then(res => res.json())
};

export const getSlides = (Code) => {
    return fetch(`${CONSTANTS.SERVER_URL}/slides/${Code}`, {
        credentials: 'include'
    }).then(res => res.json());
};

export const getComponentsForSlide = (slideId) => {
    return fetch(`${CONSTANTS.SERVER_URL}/slides/${slideId}`, {
        credentials: 'include'
    }).then(res => res.json());
};

export const createSlide = (code, order) => {
    return fetch(`${CONSTANTS.SERVER_URL}/slides`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            code,
            order
        }),
    }).then(res => res.json());
};

export const patchSlides = async (slides) => {
    const requests = [];

    for (let i = 0; i < slides.length; i++) {
        const slide = slides[i];
        const req = await fetch(`${CONSTANTS.SERVER_URL}/slides/${slide.Id}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                order: slide.Order
            })
        });
        requests.push(req.json());
    }

    return Promise.all(requests);
};

export const removeSlide = async (slide) => {
    return fetch(`${CONSTANTS.SERVER_URL}/slides/${slide.Id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => res.json());
};

export const createComponent = async (slide, component) => {
    return fetch(`${CONSTANTS.SERVER_URL}/components/${slide.Id}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(component)
    }).then(res => res.json());
};

export const updateComponent = async (component) => {
    return fetch(`${CONSTANTS.SERVER_URL}/components/${component.Id}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(component)
    }).then(res => res.json());
};

export const deleteComponent = async (component) => {
    return fetch(`${CONSTANTS.SERVER_URL}/components/${component.Type}/${component.Id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(component)
    }).then(res => res.json());
};

export const finishEditingComponent = async (component, slideId) => {
    return component.hasOwnProperty('Id') ? updateComponent(component) :  createComponent({Id: slideId}, component);
};

export const getNewsItemsForComponent = async(component) => {
    return fetch(`${CONSTANTS.SERVER_URL}/components/${component.Id}`, {
        credentials: 'include',
    }).then(res => res.json());
};

export const createNewsItem = async (newsItem, component) => {
    return fetch(`${CONSTANTS.SERVER_URL}/news`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ComponentId: component.Id,
            Title: component.Title,
            ...newsItem,
        })
    }).then(res => res.json());
};
