import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export default function LoadingComponent() {
    return (
        <div style={{
            fontSize: '5em',
            color: '#2196f3',
            height: 'calc(100% - 64px)',
            display: 'flex', // make us of Flexbox
            alignItems: 'center', // does vertically center the desired content
            justifyContent: 'center', // horizontally centers single line items
            textAlign: 'center', // optional, but helps horizontally center text that breaks into multiple lines
        }}>
            <FontAwesomeIcon icon={faSpinner} spin/>
        </div>
    )
}