import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getAccount} from "../../lib/Fetch";
import {SET_ACCOUNT} from "../../constants/ActionTypes";
import PropTypes from "prop-types";
import LoadingComponent from "../LoadingComponent";

class StateGate extends Component {

    static propTypes = {
        classes: PropTypes.object,
        setAccount: PropTypes.func,
        account: PropTypes.object,
        history: PropTypes.object,
        children: PropTypes.any,
    };

    state = {
        loading: true,
    };

    componentDidMount() {
        this.checkRoute(false);
        this.getAccount();
    }

    getAccount = () => {
        this.setState({loading: true});
        getAccount()
            .then(body => {
                if (body.accounts) {
                    this.props.setAccount(body.accounts);
                }
                this.setState({loading: false});
            });
    };

    //eslint-disable-next-line
    componentDidUpdate(prevProps, prevState, snapshot) {
        const oldScreen = prevProps.account.EnergyScreen || {};
        const newScreen = this.props.account.EnergyScreen || {};

        if (
            this.props.account.Username !== prevProps.account.Username ||
            newScreen.Code !== oldScreen.Code
        ) {
            this.getAccount();
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (
            this.props.account.Username !== prevProps.account.Username ||
            this.props.account.EnergyScreen !== prevProps.account.EnergyScreen
        ) {
            this.checkRoute(!!this.props.account.Username);
            return null;
        } else if (
            this.props.account.Username !== prevProps.account.Username &&
            this.props.account.EnergyScreen === prevProps.account.EnergyScreen
        ) {
            return null;
        }
        if (this.state.loading !== prevState.loading) {
            this.checkRoute(!!this.props.account.Username);
            return null;
        }
        return null;
    }

    checkRoute = (authenticated) => {
        if (this.state.loading)
            return;
        const {account} = this.props;

        console.log('account', account, authenticated);

        if (!account.Username) {
            return this.props.history.replace('/login');
        }
        if (authenticated) {
            return this.props.history.replace('/pages');
        }
    };

    renderChildren = () => {
        return this.props.children;
    };

    renderLoadingScreen = () => {
        return (<LoadingComponent/>);
    };

    render() {
        return this.state.loading ? this.renderLoadingScreen() : this.renderChildren();
    }
}

const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = dispatch => ({
    setAccount: account => dispatch({type: SET_ACCOUNT, payload: account}),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StateGate));