import {
    SET_SLIDES,
    ADD_SLIDE
} from './../constants/ActionTypes';

const initialSlide = {
    list: []
};

const slide = (state = {...initialSlide}, action) => {
    switch (action.type) {
        case SET_SLIDES:
            return {
                ...state,
                ...action.payload,
            };
        case ADD_SLIDE:
            return {
                ...state,
                list: [...state.list, action.payload],
            };
        default:
            return state;
    }
};

export default slide;
