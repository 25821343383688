import CustomFragment from "../../CustomFragment";

const options = {};
const disabledList = [
    'fontFamily',
    'colorPicker',
    'link',
    'emoji',
    'embedded',
    'fontSize',
    'image',
    'remove',
    'history',
];
disabledList.forEach(option => {
    options[option] = {
        component: CustomFragment
    }
});
options.inline = {
    options: ['bold', 'italic']
};
options.blockType = {
    options: ['Normal', 'H2']
};
options.textAlign = {
    options: ['left', 'center']
};
options.list = {
    options: ['ordered', 'unordered']
};

export default options;