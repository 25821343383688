const Dictionary = {
    EmptyComponent: {
        dutchReadable: 'Leeg',
    },
    DataComponent: {
        dutchReadable: 'Opwek'
    },
    IconComponent: {
        dutchReadable: 'Icoontje'
    },
    LegacyKioskComponent: {
        dutchReadable: 'Oude kiosk'
    },
    NewsComponent: {
        dutchReadable: 'Nieuws',
    },
    TextComponent: {
        dutchReadable: 'Tekst',
    },
    ImageComponent: {
        dutchReadable: 'Afbeelding'
    },
    PanelInfoComponent: {
        dutchReadable: 'Panelen info'
    },
    TwitterfeedComponent: {
        dutchReadable: 'Twitter'
    },
    MetaphorComponent: {
        dutchReadable: 'Metaforen'
    }
};

Dictionary.getDutchReadable = (type) => {
    if (Dictionary.hasOwnProperty(type)) {
        return Dictionary[type].dutchReadable;
    }
    return '~';
};

export default Dictionary;

