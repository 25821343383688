import React, {Component} from 'react';
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {getScreen, patchScreen} from "../../lib/Fetch";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import sosLogo from "../../assets/media/sos.png";
import spoLogo from "../../assets/media/spo.png";
import ozioLogo from "../../assets/media/ozio.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    icon: {
        marginRight: theme.spacing.unit * 2,
    },
    heroUnit: {
        backgroundColor: theme.palette.background.paper,
    },
    heroContent: {
        maxWidth: 600,
        margin: '0 auto',
        padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
    },
    heroButtons: {
        marginTop: theme.spacing.unit * 4,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    cardGrid: {
        padding: `${theme.spacing.unit * 8}px 0`,
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 10px',
        maxWidth: '300px',
    },
    cardMedia: {
        maxWidth: '90%',
        width: 'auto',
        maxHeight: '92px',
        height: 'auto',
        display: 'block',
        margin: '0 auto'
    },
    cardContent: {
        flexGrow: 1,
    },
    cardActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    deleteForeverIcon: {
        position: 'relative',
        top: 0,
        right: 0,
        float: 'right',
        marginTop: '-10px',
        marginRight: '-10px',
    },
    paper: {
        position: 'absolute',
        width: '50%',
        height: '50%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
    themeButton: {
        marginRight: '16px'
    }
});

const logos = {
    sos: sosLogo,
    spo: spoLogo,
    ozio: ozioLogo
};

const themes = [
    {
        brand: 'sos',
        theme: 'primary-school',
        name: 'Basisschool'
    },
    {
        brand: 'sos',
        theme: 'secondary-school',
        name: 'Middelbare school'
    },
    {
        brand: 'spo',
        theme: 'general',
        name: 'Sportlocatie'
    },
    {
        brand: 'ozio',
        theme: 'general',
        name: 'Zorglocatie'
    },
]

class ThemeScreen extends Component {

    static propTypes = {
        account: PropTypes.object,
        setSlides: PropTypes.func,
        history: PropTypes.object,
        classes: PropTypes.object,
        addSlide: PropTypes.func,
    };

    state = {
        theme: 'sos',
        brand: 'primary-school',
        loading: false,
    };

    componentDidMount = () => {
        this.getScreen();
    };

    getScreen = () => {
        getScreen(this.props.account.EnergyScreen.Code)
            .then(body => {
                this.setState({
                    loading: false,
                    theme: body.energyscreens.Theme,
                    brand: body.energyscreens.Brand
                });
            });
    };

    patchScreen = (theme, brand) => () => {
        this.setState({loading: true, theme, brand});
        return patchScreen(this.props.account.EnergyScreen.Code, {Theme: theme, Brand: brand})
            .then(() => {
                this.getScreen()
            });
    };

    render() {
        const {classes} = this.props;
        return (
            <main>
                <div className={classes.heroUnit}>
                    <div className={classes.heroContent}>
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            Thema
                        </Typography>
                        <Typography variant="h6" align="center" color="textSecondary" paragraph>
                            Hier kunt u het thema van het energiescherm kiezen.
                        </Typography>
                    </div>
                </div>
                <div className={classNames(classes.layout, classes.cardGrid)}>
                    <Grid container spacing={16} wrap='wrap'>
                        {
                            themes.map(themeInfo => {
                                const {brand, theme, name} = themeInfo;
                                return (
                                    <Grid item key={`${brand}-${theme}`} xs={12} md={3}>
                                        <Card className={classes.card}>
                                            <CardContent className={classes.cardContent}>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    <img src={logos[brand]} className={classes.cardMedia}/>
                                                </Typography>

                                            </CardContent>
                                            <CardActions className={classes.cardActions}>
                                                <Typography style={{flexGrow: 1, textAlign: 'center'}}>
                                                    {name}
                                                </Typography>
                                                {
                                                    this.state.loading && this.state.brand === brand && this.state.theme === theme ?
                                                        <Button variant='contained' disabled={true}
                                                                style={{flexGrow: 1}}>
                                                            <div style={{
                                                                fontSize: '1.7em',
                                                                color: '#2196f3',
                                                                width: '100%',
                                                                // height: 'calc(100% - 64px)',
                                                                display: 'flex', // make us of Flexbox
                                                                alignItems: 'center', // does vertically center the desired content
                                                                justifyContent: 'center', // horizontally centers single line items
                                                                textAlign: 'center', // optional, but helps horizontally center text that breaks into multiple lines
                                                            }}>
                                                                <FontAwesomeIcon icon={faSpinner} spin/>
                                                            </div>
                                                        </Button>
                                                        :
                                                        this.state.brand === brand && this.state.theme === theme ?
                                                            <Button variant="contained" color="primary"
                                                                    style={{flexGrow: 1}}
                                                                    disabled={this.state.loading}>Actief</Button>
                                                            :
                                                            <Button variant="contained" color="secondary"
                                                                    disabled={this.state.loading}
                                                                    style={{flexGrow: 1}}
                                                                    onClick={this.patchScreen(
                                                                        theme,
                                                                        brand,
                                                                    )}>Activeer</Button>
                                                }
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </div>
            </main>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account,
});

export default withStyles(styles)(connect(mapStateToProps)(ThemeScreen));
