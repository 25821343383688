import React, {Fragment} from 'react';
import {Button, Grid, withStyles} from '@material-ui/core';
import styles from '../styles';
import PropTypes from "prop-types";
import classNames from 'classnames';
import PanelInfoOptions from './PanelInfoOptions';

function PanelInfoComponent(props) {
    const {classes, editComponent, component} = props;

    const buttons = PanelInfoOptions.map(({info, dutchReadable}) => (
        <Button
            key={info}
            variant={component.Info === info ? 'contained' : 'outlined'}
            className={classes.componentTypePickerButton}
            onClick={() => {
                editComponent('Info', info);
                editComponent('Name', dutchReadable);
            }}
        >
            {dutchReadable}
        </Button>
    ));


    return (
        <Fragment>
            <Grid container className={classNames([classes.root, classes.rootHeightEncase])} spacing={16}>
                <Grid item md={4} xs={12} className={classes.rootHeightEncaseComponents}>
                    {buttons}
                </Grid>
            </Grid>
        </Fragment>
    )
}

PanelInfoComponent.propTypes = {
    classes: PropTypes.object,
    name: PropTypes.string,
    editComponent: PropTypes.func,
    component: PropTypes.object,
};

export default withStyles(styles)(PanelInfoComponent);