/* eslint no-console: 0 */  // --> OFF

import React, {Component} from 'react';
import {Provider} from "react-redux";
import {createStore, applyMiddleware, compose} from 'redux';
import reducers from './reducers';
import './App.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import logger from 'redux-logger';
import {BrowserRouter as Router, Route} from "react-router-dom";
import HomepageLayout from "./components/Layout";
import LoginScreen from './components/LoginScreen';
import ScanQRScreen from './components/ScanQRScreen';
import SlidesScreen from './components/SlidesScreen';
import WidgetScreen from './components/WidgetScreen';
import ThemeScreen from './components/ThemeScreen';
import ComponentsScreen from './components/ComponentsScreen';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import blue from '@material-ui/core/colors/blue';
import AuthorizationGate from "./components/StateGate";

let middleware = [];
let enhancers = [applyMiddleware(...middleware)];

if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
    if (window.__REDUX_DEVTOOLS_EXTENSION__)
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(reducers, compose(...enhancers));

const theme = createMuiTheme({
    palette: {
        primary: {main: blue[500]}, // Purple and green play nicely together.
        // secondary: {main: '#11cb5f'}, // This is just green.A700 as hex.
    },
    typography: {useNextVariants: true},
});

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Router>
                            <HomepageLayout>
                                <AuthorizationGate>
                                    <Route path="/login" component={LoginScreen}/>
                                    <Route path="/activate" component={ScanQRScreen}/>
                                    <Route path="/pages" component={SlidesScreen}/>
                                    <Route path="/widget" component={WidgetScreen}/>
                                    <Route path="/theme" component={ThemeScreen}/>
                                    <Route path="/blocks/:pageId" component={ComponentsScreen}/>
                                </AuthorizationGate>
                            </HomepageLayout>
                        </Router>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </Provider>
        );
    }
}

export default App;
