import React, {Component} from 'react';
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import {SortableContainer as sortableContainer, SortableElement as sortableElement} from "react-sortable-hoc";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {withStyles} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {deleteComponent, finishEditingComponent, getComponentsForSlide} from "../../lib/Fetch";
import arrayMove from "array-move";
import {connect} from "react-redux";
import ComponentModal from "./ComponentModal";
import Dictionary from "./Components/Dictionary";
import PropTypes from "prop-types";
import LoadingComponent from "../LoadingComponent";
import DragIndicator from '@material-ui/icons/DragIndicator';

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    icon: {
        marginRight: theme.spacing.unit * 2,
    },
    heroUnit: {
        backgroundColor: theme.palette.background.paper,
    },
    heroContent: {
        maxWidth: 600,
        margin: '0 auto',
        padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
    },
    heroButtons: {
        marginTop: theme.spacing.unit * 4,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    cardGrid: {
        padding: `${theme.spacing.unit * 8}px 0`,
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    deleteForeverIcon: {
        position: 'relative',
        top: 0,
        right: 0,
        float: 'right',
        marginTop: '-10px',
        marginRight: '-10px',
    },
});

const SortableItem = sortableElement(({component, classes, editComponent}) => (
    <Grid item key={component.Position} sm={6} md={4} lg={4}>
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2"
                            style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {Dictionary.getDutchReadable(component.Type)}
                    <DragIndicator/>
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary" onClick={() => editComponent(component)}>
                    {component.Type === 'EmptyComponent' ? 'Toevoegen' : 'Bewerken'}
                </Button>
            </CardActions>
        </Card>
    </Grid>
));

const SortableContainer = sortableContainer(({children}) => {
    return (
        <Grid container spacing={40}>
            {children}
        </Grid>
    );
});

class ComponentsScreen extends Component {

    static propTypes = {
        classes: PropTypes.object,
        match: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            slide: {
                Components: []
            },
            modalOpen: false,
            updated: false,
            loading: true,
            selectedComponent: this.createEmptyComponent(0)
        };
    }

    componentDidMount = () => {
        this.getComponents();
    };

    createEmptyComponent = (position = 0) => {
        return {
            Position: position,
            Type: 'EmptyComponent',
        }
    };

    getComponents = () => {
        this.setState({loading: true});
        getComponentsForSlide(this.props.match.params.pageId)
            .then(body => {
                let stateUpdate = {
                    slide: body.slides,
                    loading: false,
                };

                if (this.state.selectedComponent.Id) {
                    const comp = body.slides.Components.find(component => component.Id === this.state.selectedComponent.Id);
                    if (comp)
                        stateUpdate.selectedComponent = comp;
                } else if (this.state.selectedComponent.Position) {
                    const comp = body.slides.Components.find(component => component.Position === this.state.selectedComponent.Position);
                    if (comp)
                        stateUpdate.selectedComponent = comp;
                }

                console.log('stateUpdate', stateUpdate);

                this.setState(stateUpdate);
            });
    };

    saveComponent = (cb, component = this.state.selectedComponent) => {
        this.setState({loading: true});
        const request = finishEditingComponent(component, this.state.slide.Id);

        request.then((body) => {
            this.setState({loading: false});
            if (body.error) {
                if (cb)
                    cb(false);
            } else {
                if (cb)
                    cb(true);
                this.getComponents();
            }
        })
    };

    removeComponent = (cb) => {
        this.setState({loading: true});

        const selectedComponent = this.state.selectedComponent;
        deleteComponent(selectedComponent)
            .then((body) => { //body
                if (body.error) {
                    return this.showSnackbar('Er ging iets fout met verwijderen, probeer het zo nog eens', false);
                }
                cb(true);
                this.handleCloseModal();
                this.getComponents();
            });
    };

    handleCloseModal = () => {
        const newState = {
            modalOpen: false, selectedComponent: this.createEmptyComponent(this.state.selectedComponent.Position)
        };

        this.setState(newState)
    };

    onSortEnd = ({oldIndex, newIndex}) => {
        if(oldIndex === newIndex)
            return;

        this.setState({loading: true});
        const components = [...arrayMove(this.generateComponents(), oldIndex, newIndex)];


        console.log(components);
        // this.setState({components});

        components.forEach((component, index) => {
            component.Position = index + 1;
        });

        components.forEach(comp => this.saveComponent(null, comp));

    };

    generateComponents = () => {
        const comp = this.state.slide.Components.find(slideComp => slideComp.Position === 1) || {};
        if (comp.Type === 'LegacyKioskComponent')
            return [comp];

        return new Array(6)
            .fill({
                Type: 'EmptyComponent'
            })
            .map((obj, index) => {
                const Position = index + 1;
                const compForBlock = this.state.slide.Components.find(slideComp => slideComp.Position === Position);
                if (compForBlock)
                    return compForBlock;

                return {
                    ...obj,
                    Position,
                };
            });
    };

    editComponent = (property, value) => {
        const {selectedComponent} = this.state;
        selectedComponent[property] = value;
        this.setState({selectedComponent});
    };

    handleEditComponent = component => {
        this.setState({modalOpen: true, selectedComponent: component});
    };

    renderComponents = () => {
        if (this.state.loading)
            return (<LoadingComponent/>);

        const {classes} = this.props;
        const components = this.generateComponents();
        if (components.length === 1) {
            const component = components[0];
            return (
                <Grid item key={component.Position} xs={12} style={{height: '25em'}}>
                    <Card className={[classes.card]} id={'testing!'}>
                        <CardContent className={classes.cardContent}>
                            <Typography gutterBottom variant="h4" component="h2">
                                {Dictionary.getDutchReadable(component.Type)}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" color="primary" onClick={() => this.handleEditComponent(component)}>
                                {component.Type === 'EmptyComponent' ? 'Toevoegen' : 'Bewerken'}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            );
        }


        return components.map((component, index) => {
            return (
                <SortableItem
                    key={`item-${component.Position}`}
                    removeComponent={this.removeComponent}
                    index={index}
                    component={component}
                    classes={classes}
                    editComponent={this.handleEditComponent}
                />
            )
        });
    };

    render() {
        const {classes} = this.props;
        const {selectedComponent, slide, modalOpen} = this.state;

        return (
            <main>
                <div className={classes.heroUnit}>
                    <div className={classes.heroContent}>
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            Pagina nr. {this.state.slide.Order || 'x'}
                        </Typography>
                        <Typography variant="h6" align="center" color="textSecondary" paragraph>
                            Hier kunt U nieuwe blokken toevoegen of verwijderen
                        </Typography>
                    </div>
                </div>
                <div className={classNames(classes.layout, classes.cardGrid)}>
                    <SortableContainer
                        axis="xy"
                        onSortEnd={this.onSortEnd}
                        pressDelay={300}
                    >
                        {this.renderComponents()}
                    </SortableContainer>
                </div>
                <ComponentModal
                    component={selectedComponent}
                    slide={slide}
                    handleClose={this.handleCloseModal}
                    open={modalOpen}
                    editComponent={this.editComponent}
                    reloadComponents={this.getComponents}
                    saveComponent={this.saveComponent}
                    removeComponent={this.removeComponent}
                />
            </main>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account,
    slide: state.slide,
});

export default withStyles(styles)(connect(mapStateToProps)(ComponentsScreen));