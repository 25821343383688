import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from './AppBar';
import {logout} from "../../lib/Fetch";
import {ADD_SLIDE, LOGOUT, SET_ACCOUNT, SET_SLIDES} from "../../constants/ActionTypes";
import {withRouter} from "react-router-dom";
import Sidebar from "./Sidebar";

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
    h5: {
        marginBottom: theme.spacing.unit * 2,
    },
});

class MainLayout extends Component {

    static propTypes = {
        history: PropTypes.object,
        logout: PropTypes.func,
        children: PropTypes.any,
    };

    state = {
        open: true,
    };

    handleLogout = () => {
        logout()
            .then(body => {
                if (body.accounts === null) {
                    this.props.logout();
                }
            })
    };

    handleDrawerOpen = () => {
        this.setState({open: true});
    };

    handleDrawerClose = () => {
        this.setState({open: false});
    };

    render() {
        const {classes, history} = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar
                    drawerOpen={this.state.open}
                    handleDrawerClose={this.handleDrawerClose}
                    handleDrawerOpen={this.handleDrawerOpen}
                    handleLogout={this.handleLogout}
                />
                <Sidebar classes={classes} history={history} handleDrawerClose={this.handleDrawerClose} drawerOpen={this.state.open}/>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer}/>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

MainLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    account: state.account,
    slide: state.slide,
});

const mapDispatchToProps = dispatch => ({
    setAccount: account => dispatch({type: SET_ACCOUNT, payload: account}),
    logout: () => dispatch({type: LOGOUT}),
    setSlides: slides => dispatch({type: SET_SLIDES, payload: slides}),
    addSlide: slide => dispatch({type: ADD_SLIDE, payload: slide}),
});

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MainLayout)));
